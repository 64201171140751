@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

html,
body,
.App {
  overflow: hidden;
  position: fixed;
  margin: 0;

  width: 100%;
  height: 100%;

  font-family: "Roboto", sans-serif;
}
